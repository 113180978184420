<template>
  <div class="card card-custom px-md-10">
    <div class="card-body p-0">
      <div class="row px-8 px-lg-10 py-8">
        <h2>Müşteri Ekle</h2>
      </div>
      <!--begin: Wizard-->
      <div
        class="wizard wizard-1"
        id="kt_wizard_v1"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav">
          <div class="wizard-steps pt-8 pt-lg-10 px-8 px-lg-10 mx-4">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-label">
                <div class="wizard-box">
                  <p>1</p>
                </div>
              </div>
              <div class="wizard-divider d-lg-block d-none"></div>
              <p class="wizard-text d-block d-lg-none">Müşteri Çeşidi</p>
            </div>

            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <div class="wizard-box">
                  <p>2</p>
                </div>
              </div>
              <div class="wizard-divider d-lg-block d-none"></div>
              <p class="wizard-text d-block d-lg-none">Müşteri Bilgileri</p>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <div class="wizard-box">
                  <p>3</p>
                </div>
              </div>
              <p class="wizard-text d-block d-lg-none">Müşteri Belgeleri</p>
            </div>
          </div>
          <div class="d-lg-block d-none">
            <div
              class="d-flex px-8 px-lg-10 pb-8 pb-lg-10 pt-2 justify-content-between"
            >
              <p class="wizard-text">Müşteri Çeşidi</p>
              <p class="wizard-text">Müşteri Bilgileri</p>
              <p class="wizard-text">Müşteri Belgeleri</p>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav-->

        <!--begin: Wizard Body-->
        <div class="row justify-content-center my-10 px-8 my-lg-15 px-lg-10">
          <div class="col-xl-12 col-xxl-9">
            <!--begin: Wizard Form-->
            <form class="form" id="kt_form">
              <!--begin: Wizard Step 1-->
              <div
                class="pb-5"
                data-wizard-type="step-content"
                data-wizard-state="current"
              >
                <h3 class="mb-10 font-weight-bold text-dark">Müşteri Çeşidi</h3>
                <div class="row justify-content-center">
                  <div class="col-12">
                    <div class="radio-container">
                      <b-form-radio-group
                        class="radio-group"
                        id="radio-type"
                        name="radio-sub-component"
                        v-model="MusteriTipi"
                      >
                        <b-form-radio size="lg" value="Bireysel"
                          >Bireysel</b-form-radio
                        >
                        <b-form-radio size="lg" value="Ticari"
                          >Ticari</b-form-radio
                        >
                        <b-form-radio size="lg" value="Özel"
                          >Özel</b-form-radio
                        >
                        <b-form-radio size="lg" value="Kiracı"
                          >Kiracı</b-form-radio
                        >
                      </b-form-radio-group>
                    </div>
                  </div>
                </div>
              </div>
              <!--end: Wizard Step 1-->

              <!--begin: Wizard Step 2-->
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">
                  Müşteri Bilgileri
                </h4>
                <div class="row">
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>İsim</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="package"
                        placeholder="İsim"
                        v-model="Adi"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>Soyisim</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="package"
                        placeholder="Soyisim"
                        v-model="Soyadi"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>T.C. Kimlik Numarası</label>
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        name="package"
                        placeholder="TCKN"
                        v-model="Tckn"
                      />
                    </div>
                  </div>
                  
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>E-Posta</label>
                      <input
                        type="email"
                        class="form-control form-control-lg"
                        name="package"
                        placeholder="TCKN"
                        v-model="Eposta"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <label>Şifre</label>
                      <input
                        type="password"
                        class="form-control form-control-lg"
                        name="package"
                        placeholder="Şifre"
                        v-model="Sifre"
                      />
                  </div>
                </div>
                
                <div class="form-group">
                  <label>Doğum Tarihi</label>
                  <masked-input
                    class="form-control form-control-lg"
                    mask="11/11/1111"
                    placeholder="GG/AA/YY"
                    v-model="DogumTarihi"
                  />
                </div>
                <div class="form-group">
                  <label>Telefon Numarası</label>
                  <masked-input
                    class="form-control form-control-lg"
                    v-model="Telefon"
                    mask="\+\90 (111) 111-1111"
                    placeholder="+90 (5__) ___-____"
                  />
                </div>
                <div class="form-group">
                  <MultiSelect
                    :key="counties.length"
                    v-model="county"
                    :options="counties"
                    :multiple="false"
                    label="İl"
                    placeholder="İl Seçiniz"
                    id="il"
                  />
                </div>
                <div class="form-group">
                  <MultiSelect
                    :key="districts.length"
                    v-model="district"
                    :options="districts"
                    :multiple="false"
                    label="İlçe"
                    placeholder="İlçe Seçiniz"
                    id="ilce"
                  />
                </div>
                <div class="form-group">
                  <label>Adres</label>
                  <Textarea v-model="AcikAdres"/>
                </div>
             
              </div>
              <!--end: Wizard Step 2-->

              <!--begin: Wizard Step 3-->
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">
                  Müşteri Bağlantıları
                </h4>
                <MultiSelect
                    :key="supports.length"
                    v-model="support"
                    :options="supports"
                    :multiple="false"
                    label="Bağlı Danışman"
                    placeholder="Bağlı olduğu danışmanı seçiniz"
                    id="danisman"
                  />
              </div>
              <!--end: Wizard Step 3-->

              <!--begin: Wizard Actions -->
              <div class="d-flex justify-content-between border-top pt-10">
                <div class="mr-2">
                  <button
                    class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-prev"
                  >
                    Geri
                  </button>
                </div>
                <div>
                  <button
                    type="submit"
                    v-on:click="submit"
                    class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-submit"
                  >
                    Gönder
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-next"
                  >
                    İleri
                  </button>
                </div>
              </div>
              <!--end: Wizard Actions -->
            </form>
            <!--end: Wizard Form-->
          </div>
        </div>
        <!--end: Wizard Body-->
      </div>
    </div>
    <!--end: Wizard-->
  </div>
</template>

<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-1.scss';

.wizard-text {
  color: $dveb-primary;
  font-size: 1.38462rem;
  font-family: 'Poppins';
  line-height: 1.53846rem;

  @media (max-width: 990px) {
    flex: auto;
    text-align: start;
    margin-left: 1rem;
  }

  &:not(:last-child) {
    flex: 1;
  }
}

.wizard-step:not(:last-child) {
  flex: 1;
  position: relative;
}

.wizard.wizard-1 .wizard-nav .wizard-steps .wizard-step .wizard-label {
  margin: 0;
}

.wizard-divider {
  height: 1rem;
  background-color: $dveb-divider;
  flex: 1;

  &::before {
    content: '';
    display: block;
    width: 3rem;
    height: 3rem;
    border-radius: 70%;
    background-color: $dveb-divider;
    position: absolute;
    left: 3rem;
    top: 0;
    margin-left: -2rem;
    margin-top: 0.32rem;
  }

  &::after {
    content: '';
    display: block;
    width: 3rem;
    height: 3rem;
    border-radius: 70%;
    background-color: $dveb-divider;
    position: absolute;
    right: 3rem;
    top: 0;
    margin-right: -5.7rem;
    margin-top: 0.32rem;
  }
}

.wizard-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.6875rem;
  height: 3.6875rem;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid $dveb-divider;
  font-size: 18px;
  font-weight: 600;
  position: relative;
  z-index: 2;
}

.wizard-step[data-wizard-state='current'],
.wizard-step[data-wizard-state='done'] {
  .wizard-label .wizard-box {
    background-color: $dveb-info;
    color: #fff;
    border: none;
  }
}

.wizard-step[data-wizard-state='done'] {
  .wizard-divider {
    background-color: $dveb-info;
  }
  .wizard-divider::before {
    content: '';
    display: block;
    width: 3rem;
    height: 3rem;
    border-radius: 70%;
    background-color: $dveb-info;
    position: absolute;
    left: 3rem;
    top: 0;
    margin-left: -2rem;
    margin-top: 0.32rem;
  }
  .wizard-divider::after {
    content: '';
    display: block;
    width: 3rem;
    height: 3rem;
    border-radius: 70%;
    background-color: $dveb-info;
    position: absolute;
    right: 3rem;
    top: 0;
    margin-right: -5.7rem;
    margin-top: 0.32rem;
  }
}

.radio-container {
  border: 0.5px solid rgba($dveb-primary, 0.5);
  padding: 3.07692rem;
  border-radius: 10px;

  .radio-group {
    display: flex;
    justify-content: space-between;

    @media (max-width: 990px) {
      flex-direction: column;
      gap: 1.5rem;
    }

    label {
      font-size: 1.53846rem;
      cursor: pointer;

      &::before {
        border-radius: 5px;
        width: 1.84615rem;
        height: 1.84615rem;
        border-color: rgba($dveb-primary, 1);
        border-width: 2px;
        left: -3rem;
      }

      &::after {
        width: 1.84615rem;
        height: 1.84615rem;
        left: -3rem;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3E%3C/svg%3E") !important;
      }
    }
  }
}

.form-control {
  border-color: $dveb-primary;
  background-color: #fff;

  &:focus {
    background-color: inherit;
  }
}

</style>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import KTUtil from '@/assets/js/components/util';
import KTWizard from '@/assets/js/components/wizard';
import MaskedInput from 'vue-masked-input';
import { mapGetters } from 'vuex';
import {COUNTIES,DISTRICTS} from '@/core/services/store/location.module.js'

export default {
  name: 'musteri-ekle',

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Mülker' },
      { title: 'Mülk Ekle' },
    ]);

    // Initialize form wizard
    const wizard = new KTWizard('kt_wizard_v1', {
      startStep: 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });

    // Validation before going to next page
    wizard.on('beforeNext', function (/*wizardObj*/) {
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    });

    // Change event
    wizard.on('change', function (/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  data() {
    return {
      MusteriTipi : "",
      BagliDanismanId:"",
      Adi:"",
      Soyadi:"",
      Tckn:"",
      Eposta:"",
      Telefon:"",
      DogumTarihi:"",
      Sifre:"",
      AcikAdres:"",
      counties:[],
      districts:[],
      county:{},
      district:{},
      supports:[],
      support:{}
    };
  },
  created(){
    this.$store.dispatch(COUNTIES);
    this.getSupport()
  },
  methods: {
    submit: function (e) {
      e.preventDefault();
    
      this.sendForm().then((res)=>{
        if(res.status === 200){
          this.$generateNotification(
              this,
              "success",
              "Kayıt Başarıyla Eklendi."
            );
        }else{
          this.$generateNotification(
              this,
              "error",
              "Kayıt Eklenirken Bir Hata Oluştu."
            );
        }
      }).catch((err)=>{
        this.$generateNotification(
              this,
              "error",
              result.result
            );
      })
    },
    getSupport(){
      let query = `sistemkullanicilari_list?&token=${this.myUser}&searchValue=Danışman&column=KullaniciTipi`;

      this.$ApiService
        .get(query)
        .then((res) => {
          if (res.status == 200) {
           res.data.map((res)=>{
            let supportObject = {value:res._id.$oid , text : res.Adi +" " +res.Soyadi}
            this.supports.push(supportObject)
           })
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });

    },
    sendForm(){
      const formData = {
       BagliDanismanId : this.support.value,
       Adi : this.Adi,
       Soyadi : this.Soyadi,
       Tckn :this.Tckn,
       Eposta : this.Eposta,
       Telefon : this.Telefon,
       DogumTarihi : this.DogumTarihi,
       AcikAdres : this.AcikAdres,
       IlId: this.county.value,
       IlceId : this.district.value,
       SozlesmeId : 1,
       OlusturmaZamani : new Date().toLocaleDateString("tr"),
       IsDeleted : Boolean( false),
       IsActive : Boolean(true),
       MusteriTipi : this.MusteriTipi,
       Sifre: this.Sifre,
       EpostaAktivasyon:false,
       TelefonAktivasyon:false,
       token:this.myUser
       
      }
      return new Promise((resolve, reject) => {
        this.$ApiService
          .post('musteriler_create', formData)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });

        });
    },
    setCountiesSelectValues(county) {
      if (county?.length > 0) {
        const newCounties = county.map((index, key) => {
          return { text: index.il_adi, value: index.il_id };
        });
        this.counties = newCounties;
      }

      this.countiesLoading = false;
    },
    setDistrictsSelectValues(districts) {
      const setDistrictsSelect = districts.map((index, key) => {
        return { text: index.ilce_adi, value: index.ilce_id };
      });
      return setDistrictsSelect;
    },
    onCountyChange() {
      if (this.county) {
        this.$store
          .dispatch(DISTRICTS, this.county.value)
          .then((districts) => {
            const newDistricts = this.setDistrictsSelectValues(districts);
            this.districts = newDistricts;
          });
      } else {
        this.districts = [];
      }
    },

 
  },
  components: {
    MaskedInput,
  },
  watch:{
    getCounties(newCounties) {
      if (newCounties.length > 0) {
        this.setCountiesSelectValues(newCounties);
      }
    },
    county:"onCountyChange",
  },
  computed:{
    ...mapGetters(['myUser','getCounties','getDistricts'])
  }
};
</script>
